import {BasicPopout, Icon} from '@eitje/web_components'
import {RelativeTime, Text, Layout} from 'common/components'
import {useDebug} from 'helpers'
import {ColorCircle} from 'components/ui'
import {t} from 'initializers/i18n'
import './inbox_item.less'
export const InboxItem = props => {
	let {read, label, createdAt, icon, extLink, to, error, warning, issues, size, id} = props
	const iconName =
		icon ||
		(error && 'exclamation-mark-circled') ||
		(extLink && 'download') ||
		(warning && 'exclamation-mark-circled') ||
		(to && 'caret-right')
	const iconFill = error ? Colors.mediumRed : warning ? Colors.mediumOrange : undefined
	const small = size === 'small'

	const issueMessages = issues?._map('message').uniq()

	let _issues = issueMessages?.join('\n\n')

	const isJson = issueMessages && issueMessages.length > 0 && issueMessages.every(isValidJSON)
	if (isJson) _issues = <pre>{JSON.stringify(JSON.parse(_issues), null, 2)} </pre>

	const status = error ? 'error' : warning ? 'warning' : 'info'
	const popoutTitle = _issues && t(`inbox.item.popout.${status}`)
	const debug = useDebug()
	label = addDotIfMissing(label)

	return (
		<Layout
			to={to}
			extLink={extLink}
			className="inbox-item"
			width="full"
			horizontal="spaceBetween"
			height={small ? 48 : 70}
			vertical="center"
			padding={small ? '12 8' : '16 24'}
			colorSet="grey-bordered"
			gap={12}
			borderBottom
		>
			<Layout onClick={debug ? () => console.log(props) : _.noop} direction="vertical" grow gap={small ? 0 : 4}>
				<Layout>
					{!read && <ColorCircle color={Colors.mediumBlue} size={small ? 8 : 10} />}
					<RelativeTime date={createdAt} darkGrey small />
					{debug && <Text darkGrey small children={`(ID ${id})`} />}
				</Layout>

				<Text truncate children={label} small={small} popoutTitle={popoutTitle} popoutBody={_issues} />
			</Layout>

			{/* TODO: make icon solid on error and warning once Chris added the functionality to Icon in component lib */}
			{iconName && <Icon name={iconName} fill={iconFill} size={small ? 14 : 16} />}
		</Layout>
	)
}

function addDotIfMissing(str) {
	str = _.trimEnd(str)

	const lastChar = str[str.length - 1]
	if (lastChar != '.' && lastChar != '?') str += '.'

	return str
}

function isValidJSON(jsonString) {
	try {
		JSON.parse(jsonString)
		return true
	} catch {
		return false
	}
}
