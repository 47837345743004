import {PrivateRoute, ProtectedRoute} from 'components/private_route'

import {
	IndexPage,
	ActivateIntegration,
	ActivateLightspeed,
	ActivateLightspeedK,
	LightspeedKActivated,
	ActivateLoket,
	Issues,
	SyncHistory,
	SetupRevenue,
	SetupHr,
	SetupBase,
	ExportHours,
	OauthActivated,
	ExportUsers,
} from 'cores/integrations/index'

const integrationForegroundRoutes = [
	<PrivateRoute path="/integrations/:providerName/oauth" component={OauthActivated} exact />,
	<ProtectedRoute path="/integrations" component={IndexPage} roleInAnyEnv="admin" />,
	<PrivateRoute path="/oauth/lightspeed-k" component={LightspeedKActivated} exact />,
	<PrivateRoute path="/oauth/:providerName" component={OauthActivated} exact />,
]

const integrationBackgroundRoutes = [
	<PrivateRoute path="/integrations/lightspeed/activate" component={ActivateLightspeed} exact />,
	<PrivateRoute path="/integrations/lightspeed_k/activate" component={ActivateLightspeedK} exact />,
	<PrivateRoute path="/integrations/loket/activate" component={ActivateLoket} exact />,
	<PrivateRoute path="/integrations/:providerName/activate" component={ActivateIntegration} exact />,
	<PrivateRoute path="/integrations/fooks/:id/export_users" component={ExportUsers} exact />,

	<PrivateRoute path="/integrations/:provider_name/:id/fix_provider_issues" component={Issues} exact />,
	<PrivateRoute path="/integrations/:provider_name/:id/sync_history" component={SyncHistory} exact />,
	<PrivateRoute path="/integrations/:integ_name/:id/setup_revenue" component={SetupRevenue} />,
	<PrivateRoute path="/integrations/:integ_name/:id/setup_hr" component={SetupHr} />,
	<PrivateRoute path="/integrations/:integ_name/:id/setup_base" component={SetupBase} />,
	<ProtectedRoute roleInAnyEnv="financieel" path="/integrations/:integ_name/:id/export_hours" component={ExportHours} />,
]

export {integrationBackgroundRoutes, integrationForegroundRoutes}
