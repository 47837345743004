import {useChatContext} from 'stream-chat-react'
import {store} from 'index'
import {useChannelMetaData} from 'cores/chat'
import {navigate} from 'components/routing'
import {BaseHeader} from './base'
import {IconButton} from './icon_button'

export const ChannelShowHeader = ({channel}) => {
	const {setActiveChannel} = useChatContext()
	const {title} = useChannelMetaData(channel)

	const handleBack = () => setActiveChannel(null)
	const handleOpenChat = () => {
		store.dispatch({type: 'SET_INITIAL_CHANNEL', initialChannel: channel.id})
		navigate('/chat')
	}

	return (
		<BaseHeader
			title={title}
			actions={
				<>
					<IconButton name="arrow-left" onClick={handleBack} />
					<IconButton name="magnifying-glass-plus" onClick={handleOpenChat} />
				</>
			}
		/>
	)
}
