import React, {useEffect, useState, useRef} from 'react'
import {LegacyDropdownPicker as DropdownPicker} from '@eitje/form-fields-web'
import AvForm from './availability_form'
import {t} from 'initializers/i18n'
import {Button} from 'components/ui'
import useAvShift from 'cores/availability/hooks/use_av_shift'
import utils from '@eitje/web_utils'
const modes = ['available', 'unavailable', 'partly_available']
import {useAvFormContext} from 'cores/availability/components/form/availability_form_provider'
import {useUserContext} from 'contexts/user'
import '../../styles/availability.less'
import {capitalize} from 'lodash-es'
import {mapToDropDownTranslation} from 'cores/availability/pages/settings'
import {useNewOutsideClick} from 'hooks/use_outside_click'

const AvLeaveForm = () => {
	const {initialDate, kind, hiddenModes, selectedDates, hideForm, setFormMode} = useAvFormContext()
	const {userId} = useUserContext()
	const _modes = modes.filter(m => !hiddenModes.includes(m))
	const form = useRef(null)
	const avUser = useAvShift({date: initialDate, user_id: Number(userId)}, {kind})
	const [mode, setMode] = useState(avUser.status == 'unknown' ? null : avUser.status)
	const modePickerCn = utils.makeCns(!mode && 'default_popover_field_container')

	useNewOutsideClick({
		onClick: hideForm,
		parentClass: [
			'availability-form-wrapper',
			'ant-picker-panel',
			'ant-select-dropdown',
			`users-availability-table-row-${userId}`,
			'user-availability-table',
		],
	})

	const picker = (
		<div className={modePickerCn}>
			<DropdownPicker
				bordered={false}
				value={mode}
				style={{width: 300}}
				placeholder="..."
				label={t('availability.mode_picker_label')}
				items={mapToDropDownTranslation(_modes)}
				onChange={setMode}
			/>
		</div>
	)

	useEffect(() => {
		setFormMode(mode)
	}, [mode])

	return (
		<div fieldWrapper className="availability-form-wrapper">
			{!mode && picker}
			{renderForm(mode, form, {avUser, initialValues: {user_id: userId}}, picker)}
			{mode && <SubmitFooter dates={selectedDates} hideForm={hideForm} form={form} kind={mode} />}
		</div>
	)
}

const SubmitFooter = ({dates, form, kind, hideForm}) => {
	return (
		<div fieldWrapper className="availability-form-footer">
			<div>
				<h4>{capitalize(t('daysAsAvOrLeave', {count: dates.length, kind}))} </h4>
				<h5>{capitalize(t('selectDaysAvOrLeave', {kind}))} </h5>
			</div>

			<Button type="primary" onClick={() => form.current.submit({callback: hideForm})}>
				{t('submit')}
			</Button>
		</div>
	)
}

const renderForm = (mode, ref, args, picker) => {
	const sharedProps = {innerRef: ref, mode, ...args, picker}
	if (mode == 'partly_available') return <AvForm partly {...sharedProps} />
	if (mode == 'available' || mode == 'unavailable') return <AvForm {...sharedProps} />
}

export default AvLeaveForm
