import React, {createContext, useContext, useEffect, useState} from 'react'
import * as Sentry from '@sentry/react'
import {Streami18n} from 'stream-chat-react'
import {StreamChat} from 'stream-chat'
import {useSelector} from 'react-redux'
import {store} from 'index'
import {ChatWindowProvider, useStreamToken} from 'cores/chat'
import {useShared} from 'hooks'
import {t, getLanguage} from 'initializers/i18n'
import {CHAT_ENV_KEY} from 'initializers/api'

const SUBSCRIBER_EVENTS = ['message.new', 'notification.message_new', 'notification.mark_read', 'notification.mark_unread']
const API_KEY = process.env[CHAT_ENV_KEY]
const STREAM_TIMEOUT = 15000 // in ms (increasing default of 3000 because attachment search call can be slow)

export const useCreateChatClient = ({apiKey, token, userData}) => {
	const [chatClient, setChatClient] = useState(null)
	const [isFetched, setIsFetched] = useState(false)
	const [cachedUserData, setCachedUserData] = useState(userData)

	if (userData.id !== cachedUserData.id) {
		setCachedUserData(userData)
	}

	useEffect(() => {
		if (!token) return
		let didUserConnectInterrupt = false

		const client = StreamChat.getInstance(API_KEY, {timeout: STREAM_TIMEOUT})

		const connectionPromise = client
			.connectUser(cachedUserData, token)
			.then(() => {
				if (!didUserConnectInterrupt) setChatClient(client)
			})
			.catch(e => {
				Sentry.captureException(e, {
					tags: ['chat', 'stream'],
				})
			})
			.finally(() => {
				setIsFetched(true)
			})

		return () => {
			didUserConnectInterrupt = true
			setChatClient(null)
			connectionPromise
				.then(() => client.disconnectUser())
				.then(() => {
					console.log(`Connection for user "${cachedUserData.id}" has been closed`)
				})
		}
	}, [apiKey, cachedUserData, token])

	return {client: chatClient, isFetched}
}

const StreamChatContext = createContext({})
const {Provider} = StreamChatContext

export const StreamChatProvider = ({children}) => {
	const token = useStreamToken()
	const streami18nInstance = useStreami18n()
	const {me} = useShared()
	const unreadMessagesCount = useSelector(state => state.chat.unreadMessagesCount)
	const {client, isFetched} = useCreateChatClient({
		apiKey: API_KEY,
		token,
		userData: {
			id: me.id.toString(), // stream expects string type id's
		},
	})

	useEffect(() => {
		if (client?.user) {
			// set unread messages count from client initialization
			store.dispatch({type: 'SET_UNREAD_MESSAGES_COUNT', unreadMessagesCount: client.user.total_unread_count})
		}
	}, [client])

	useEffect(() => {
		if (!client) return

		const onNewMessageEvent = event => {
			const unreadCount = event.total_unread_count
			if (unreadMessagesCount !== unreadCount) {
				store.dispatch({type: 'SET_UNREAD_MESSAGES_COUNT', unreadMessagesCount: unreadCount})
			}
		}

		const subscriptions = SUBSCRIBER_EVENTS.map(e => client.on(e, onNewMessageEvent))
		return () => {
			subscriptions.map(sub => sub.unsubscribe())
		}
	}, [client, unreadMessagesCount])

	return (
		<Provider value={{client, isFetched, streami18nInstance}}>
			<ChatWindowProvider>{children}</ChatWindowProvider>
		</Provider>
	)
}

export const useStreamChatContext = () => useContext(StreamChatContext)

function useStreami18n() {
	const language = getLanguage()

	const translations = {
		'This message was deleted...': t('stream.message_deleted'),
		New: t('stream.new'),
		Reply: t('stream.reply'),
		'Reply to Message': t('stream.reply_to_message'),
		'{{ user }} is typing...': t('stream.user_typing'),
		'{{ users }} and more are typing...': t('stream.user_and_more_typing'),
		'{{ users }} and {{ user }} are typing...': t('stream.users_and_user_typing'),
	}

	return new Streami18n({
		language,
		translationsForLanguage: translations,
	})
}
