export const baseColorMapping = {
	paid: 'green',
	zero_ignore: 'green',
	disregard: 'green',
	external: 'green',
	refund: 'green',
	in_transaction: 'yellow',
	unpaid: 'red',
	chargeback: 'red',
}
