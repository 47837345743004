// Idle detection configuration
const IDLE_THRESHOLD = 10 * 60 * 1000 // 10 minutes of inactivity
let idleTimer
let userIsIdle = false

// Version checking configuration
const VERSION_CHECK_INTERVAL = 10 * 60 * 1000 // Check every 10 minutes
let currentVersion = process.env.REACT_APP_VERSION
let newVersionAvailable = false

// Set up idle detection using event-driven approach
function resetIdleTimer() {
	clearTimeout(idleTimer)
	userIsIdle = false
	idleTimer = setTimeout(() => {
		userIsIdle = true
		// If user is idle and a new version is available, reload the page
		if (newVersionAvailable) {
			window.location.reload()
		}
	}, IDLE_THRESHOLD)
}

const throttledReset = _.throttle(resetIdleTimer, 5000)

// Listen to user activity events
document.addEventListener('mousemove', throttledReset, {passive: true})
document.addEventListener('keypress', throttledReset, {passive: true})
document.addEventListener('touchstart', throttledReset, {passive: true})

// Start the initial idle timer
resetIdleTimer()

// Function to check for a new version
async function checkForNewVersion() {
	try {
		const response = await fetch('/version.json', {cache: 'no-cache'})

		if (response.ok) {
			const data = await response.json()

			const fetchedVersion = data.version

			if (fetchedVersion && fetchedVersion > currentVersion) {
				// New version is available
				newVersionAvailable = true
				// If the user is already idle when we detect a new version, reload immediately
				if (userIsIdle) {
					window.location.reload()
				}
			}
		}
	} catch (error) {
		console.error('Error fetching version.json:', error)
	}
}

// Periodically check for a new version
setInterval(checkForNewVersion, VERSION_CHECK_INTERVAL)

// Perform an immediate check on page load too
checkForNewVersion()
