import utils from '@eitje/utils'
import {EitjeVirtuoso} from '@eitje/web_components'
import {useHover} from 'hooks'
import _ from 'lodash'

const FlatList = ({items, appendChild, virtual, customScrollParent = '.user-list-base', ...rest}) => {
	const innerList = virtual ? (
		<EitjeVirtuoso
			customScrollParent={customScrollParent}
			data={items}
			defaultItemHeight={50} // random value to ensure virtuoso loads if first item of list returns `null` or something without height
			itemContent={(idx, row) => <FlatListItem key={row?.id || idx} items={items} item={row} idx={idx} {...rest} />}
		/>
	) : (
		items.map((i, idx) => <FlatListItem key={i?.id || idx} items={items} item={i} idx={idx} {...rest} />)
	)

	return (
		<div className="flat-list">
			{innerList}
			{appendChild}
		</div>
	)
}

let FlatListItem = ({item = {}, isActive = _.noop, id, idx, items = [], onClick = _.noop, ListItem, itemProps, className}) => {
	itemProps = utils.funcOrVal(itemProps, item)
	const isAct = isActive(item)

	const {isHovering, hoverActions} = useHover()

	const _classNames = utils.makeCns('list-item', isAct && 'list-item-active', isHovering && 'hovering', className)

	return (
		<div className={_classNames} id={`${id}-${idx}`} onClick={() => onClick(item)} {...hoverActions}>
			<ListItem
				item={item}
				idx={idx}
				isLast={idx === items.length - 1}
				isFirst={idx == 0}
				onClick={() => onClick(item)}
				isActive={isAct}
				isHovering={isHovering}
				{...itemProps}
			/>
		</div>
	)
}

// const areEqual = (prevProps, nextProps) => {
//   const {item: prevItem} = prevProps
//   const {item} = nextProps
//   let isEqual;
//   if(item.updated_at) {
//    isEqual = item.updated_at == prevItem.updated_at
//  }

//  return isEqual;
// }
// FlatListItem = React.memo(FlatListItem, areEqual)

export {FlatList, FlatListItem}
export default FlatList
