import {createContext, useCallback, useContext, useState} from 'react'
import {store} from 'index'

const ChatWindowContext = createContext({})

export const ChatWindowProvider = ({children}) => {
	const [chatWindowOpen, setChatWindowOpen] = useState(false)

	const openChatWindow = useCallback(channel => {
		channel && store.dispatch({type: 'SET_INITIAL_CHANNEL', initialChannel: channel.id})
		setChatWindowOpen(true)
	}, [])

	const closeChatWindow = useCallback(() => {
		setChatWindowOpen(false)
	}, [])

	const state = {
		chatWindowOpen,
		openChatWindow,
		closeChatWindow,
	}

	return <ChatWindowContext.Provider value={state} children={children} />
}

export const useChatWindowContext = () => useContext(ChatWindowContext)
