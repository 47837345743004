import 'components/users' // WATCH OUT: this import is needed, otherwise -> ReferenceError: Cannot access 'useList' before initialization
import './styles/users.less'

export const makeAbbr = ({full_name, name, naam}) => {
	const _naam = full_name || name || naam
	if (!_naam || typeof _naam !== 'string') return
	const nom = _naam.split(' ').slice(0, 2)
	if (nom.length > 1) {
		return nom
			.filter(i => i)
			.map(v => v[0].toUpperCase())
			.join('')
	} else {
		return _naam.slice(0, 2).toUpperCase()
	}
}
