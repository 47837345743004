import {useFind} from '@eitje/easy_api'
import {ContextForm} from '@eitje/form'
import {DatePicker} from '@eitje/form-fields-web'
import {DeleteButton, EitjeAvatar} from 'common/components'
import {FormSubmitButton} from 'components/form'
import {FormRow} from 'components/ui'
import {useRoleOrgEnvsUsers} from 'hooks'
import {t} from 'initializers/i18n'
import {SickPeriod} from 'models/index'
import './styles/current_sick_periods.less'

const Page = () => {
	const userIds = useRoleOrgEnvsUsers('manager')._map('id')
	const periods = SickPeriod.where({end: null, user_id: userIds})

	const items = _.orderBy(periods, 'start', 'desc')
	const chunkedItems = _.chunk(items, 2)
	return (
		<div className="current-sick-periods">
			<h4 className="current-sick-periods-label"> {t('planning.team_panel.sick.current_periods')} </h4>
			{items.length == 0 && <Placeholder />}
			{chunkedItems.map(i => (
				<SickPeriodGroup items={i} />
			))}
		</div>
	)
}

const Placeholder = () => {
	return <h5 className="current-sic-periods-empty"> {t('planning.team_panel.sick.current_periods_empty')} </h5>
}

const SickPeriodGroup = ({items}) => {
	return (
		<div className="sick-period-group">
			{items.map(i => (
				<CurrentPeriod item={i} />
			))}
		</div>
	)
}

const CurrentPeriod = ({item}) => {
	const user = useFind('users', item.user_id)

	const sharedProps = {required: true, label: true, startDateField: 'start', endDateField: 'end'}
	return (
		<div className="current-sick-period">
			<EitjeAvatar size={24} user={user} />
			<h4 className="current-sick-periods-user-name"> {user.full_name} </h4>
			<ContextForm
				onSubmit={item.update}
				transNamespace="edit_sick_period"
				initialValues={item}
				className="eitje-form-2-list-form eitje-form-2-ignore-styling"
			>
				<FormRow>
					<DatePicker {...sharedProps} isStart field="start" />
				</FormRow>
				<FormRow>
					<DatePicker {...sharedProps} isEnd field="end" />
				</FormRow>
				<FormSubmitButton showIf={({end}) => end} className="submit-button" onlyWhenTouched />
				<DeleteButton height="small" iconOnly onDel={item.destroy} />
			</ContextForm>
		</div>
	)
}

export default Page
