import {Placeholder} from 'common/components'
import {Chat} from 'stream-chat-react'
import {useStreamChatContext} from 'cores/chat'

const placeholderLayoutProps = {
	padding: '180 0',
	margin: 'auto',
	height: 'unset',
}

export const ChatClientProvider = ({children}) => {
	const {client, isFetched, streami18nInstance} = useStreamChatContext()

	// if client is available, render chat pages
	if (client)
		return (
			<Chat client={client} i18nInstance={streami18nInstance}>
				{children}
			</Chat>
		)

	// if the client fetch has finished, but there is no client, assume stream chat is having issues
	if (isFetched) return <Placeholder name="chat_down" animation="broken-egg" supportButton layoutProps={placeholderLayoutProps} />

	// if the fetch is not finished yet, show a loading placeholder
	return <Placeholder name="chat_loading" animation="chat" layoutProps={placeholderLayoutProps} />
}
