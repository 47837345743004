import {date} from 'initializers/date'
import {ModalLink} from 'components/routing'
import {t, getTimeShiftUrl, StatusCard} from 'cores/time_registration/index'
import {DurationBlock} from 'components/shared/index'
import {EitjeAvatar} from 'common/components'
import './styles/error_row.less'

const ErrorRow = ({item}) => {
	const url = getTimeShiftUrl({shift: item})
	return (
		<ModalLink to={url} className="error-row">
			<UserCard item={item} />
		</ModalLink>
	)
}

const MARGIN = [0, 8, 0, 0]

const UserCard = ({item}) => {
	const {user, date: _date, error} = item
	return (
		<div className="registration-errors-row">
			<EitjeAvatar margin={MARGIN} user={user} />
			<div className="registration-errors-user-info">
				<h5 className="registration-errors-user-name"> {user.full_name} </h5>
				<h6 className="registration-errors-shift-date"> {date(_date).format("dddd DD MMM 'YY")} </h6>
			</div>
			<DurationBlock item={item} />
			<StatusCard expanded shift={item} />
			<h6 className="registration-errors-error"> {error} </h6>
		</div>
	)
}

export default ErrorRow
