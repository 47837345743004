import {useShared} from 'hooks'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'
import {useStreamChatContext} from 'cores/chat'

export const useCreateChannel = () => {
	const {org} = useShared()
	const {client} = useStreamChatContext()

	const createChannel = async ({channelId, type, name, userIds, image}) => {
		try {
			const members = userIds.map(String) // stream expects string id's

			const channel = client.channel(type, channelId, {
				name,
				members,
				image,
				org_id: org.id, // org_id is present on channels created since eitje v4
			})

			await channel.create()
			return channel
		} catch (e) {
			utils.errorNotif({
				baseColor: 'red',
				title: t('wrong'),
				duration: 5,
			})
		}
	}

	return {createChannel}
}
