import {Fragment, useCallback, useMemo, useReducer} from 'react'
import * as Sentry from '@sentry/react'
import {ChannelList as StreamChatChannelList, useChatContext} from 'stream-chat-react'
import {t} from 'initializers/i18n'
import {EitjeButton, Layout} from 'common/components'
import {SearchEmpty} from 'hooks'
import {NAMESPACE, channelSearch} from 'cores/chat'
import {ChannelPreview} from './preview'
import {SearchResultsList} from './search_results_list'
import {SearchInput} from '../search_input'

const sort = {last_message_at: -1} // descending direction

const SearchBar = ({onSearch, clearState, query, exitSearch}) => {
	const handleClear = () => {
		clearState()
		exitSearch()
	}

	return (
		<Layout className="search-bar" padding="10 12" borderBottom width="full">
			<SearchInput onChange={onSearch} value={query} onClear={handleClear} placeholder={t(`${NAMESPACE}.search`)} />
		</Layout>
	)
}

export const ChannelList = props => {
	const [channelListKey, forceRerender] = useReducer(x => x + 1, 0)
	const {client} = useChatContext()
	const searchFunction = useCallback((params, event) => channelSearch(params, event, client), [channelSearch, client])
	const additionalChannelSearchProps = useMemo(
		() => ({
			searchFunction,
			SearchResultsList,
			SearchEmpty,
			clearSearchOnClickOutside: false,
			SearchBar,
			SearchResultsHeader: Fragment, // using a fragment to render no search results header
		}),
		[searchFunction],
	)

	const filters = {members: {$in: [client.userID]}}

	const LoadingErrorIndicator = () => {
		Sentry.captureMessage('StreamChat ChannelList Loading Error')

		const handleClick = () => {
			Sentry.captureMessage('StreamChat ChannelList Loading Error: retry-button press')
			forceRerender()
		}

		return (
			<Layout padding="8 12">
				<EitjeButton onClick={handleClick} t="common.retry" width="full" />
			</Layout>
		)
	}

	return (
		<Layout {...props} className="channel-list">
			<StreamChatChannelList
				key={channelListKey} // using a key to force a rerender when the list fails to load due to connection issues
				Preview={ChannelPreview}
				sort={sort}
				filters={filters}
				showChannelSearch
				setActiveChannelOnMount={false} // If true, sets the most recent channel received from the query as active on component mount.
				additionalChannelSearchProps={additionalChannelSearchProps}
				LoadingErrorIndicator={LoadingErrorIndicator}
			/>
		</Layout>
	)
}
