import {t} from 'initializers/i18n'
import {NAMESPACE} from 'cores/chat'
import {useRoleMessage, useShared} from 'hooks'
import {Environment} from 'models'

export const useGroupChannelDisabled = channel => {
	const {org} = useShared()
	const adminRoleMessage = useRoleMessage({roles: 'admin'})

	const {
		data: {org_id: channelOrgId, env_id},
		state: {
			members,
			membership: {user},
		},
	} = channel

	// since chat v4 we can use the org_id from the channel
	// for older channels, we can use the env_id to get the corresponding org
	const {organisation_id: envOrgId} = Environment.find(env_id)
	const orgId = channelOrgId || envOrgId

	// need to use the members array here instead of the membership object
	// since on intitial load the role is missing in the membership object
	const isOwner = members[user.id].role === 'owner'
	const disabledByRole = !isOwner && adminRoleMessage

	if (orgId) {
		const disabledByOrg = orgId !== org.id && t(`${NAMESPACE}.group_chat_button_disabled`)
		return disabledByOrg || disabledByRole
	}

	// if there is no orgId, it is an old channel from a closed env
	// for these, any admin can edit
	return disabledByRole
}
