import {DeleteButton, EitjeAvatar} from 'common/components'
import {EntityCount} from 'common/components/entity_count'
import {useRoleOrgEnvsUsers} from 'hooks'
import useList from 'hooks/use_list'
import {date} from 'initializers/date'
import {t} from 'initializers/i18n'
import {SickPeriod} from 'models/index'
import FilterFooter from '../filter_footer'
import {useTeamPanelFilter} from '../hooks/use_team_panel_filter'
import './styles/past_sick_periods.less'

const searchProps = {searchField: 'user.full_name', color: 'black'}

const Page = () => {
	const {filteredItems, date, setFilteredItems, prevFilteredItems} = useTeamPanelFilter()
	const userIds = useRoleOrgEnvsUsers('manager')._map('id')
	const currentSickPeriods = SickPeriod.whereNot({end: null})
		.sortBy('end', 'desc')
		.filter(s => userIds.includes(s.user_id))

	const {list, searchInput} = useList({
		items: filteredItems,
		showPlaceholder: true,
		Placeholder,
		searchProps,
		ListItem: SickPeriodItem,
	})
	// We decided to not have the contract type filter here because getting
	// the userEmploymentTypes per user would require  joins through or very bulky code

	return (
		<div className="past-sick-periods">
			<h4 className="past-sick-periods-label"> {t('planning.team_panel.sick.past_periods')} </h4>
			<Header />
			{list}
			<FilterFooter
				items={currentSickPeriods}
				prevFilteredItems={prevFilteredItems}
				setFilteredItems={setFilteredItems}
				searchInput={searchInput}
				date={date}
				noContractTypeFilter
			/>
		</div>
	)
}

const Placeholder = () => {
	return <h5 className="past-sick-empty"> {t('planning.team_panel.sick.past_periods_empty')} </h5>
}

const Header = () => {
	return (
		<div className="past-sick-header">
			<h4 className="past-sick-header-title"> {t('common.teammember')} </h4>
			<h4 className="past-sick-header-start">{t('planning.team_panel.sick.start_sick')}</h4>
			<h4 className="past-sick-header-end"> {t('planning.team_panel.sick.recovered_on')} </h4>
		</div>
	)
}

const MARGIN = [0, 8, 0, 0]

const SickPeriodItem = ({item, isHovering}) => {
	const {start, end, user} = item
	const amtDays = item.totalDays() + 1 // its 'including'
	return (
		<div className="past-sick-period">
			<EitjeAvatar user={user} margin={MARGIN} />
			<div className="past-sick-period-title">
				<h4>{user.full_name} </h4>
				<h5>
					{t('planning.team_panel.sick.sick_duration')}: <EntityCount name="day" count={amtDays} />
				</h5>
			</div>
			<h5 className="past-sick-period-start">{date(start).format("DD MMM 'YY")} </h5>
			<h5 className="past-sick-period-end">{date(end).format("DD MMM 'YY")} </h5>
			{isHovering && <DeleteButton className="past-sick-period-delete-button" onDel={item.destroy} />}
		</div>
	)
}

export default Page
