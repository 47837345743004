import {useChatContext} from 'stream-chat-react'
import {useSelector} from 'react-redux'
import {useAsyncEffect} from 'hooks'

export const useSetInitialChannel = () => {
	const initialChannel = useSelector(state => state.chat.initialChannel)
	const {client, setActiveChannel} = useChatContext()

	useAsyncEffect(async () => {
		if (initialChannel) {
			const channels = await client.queryChannels({id: initialChannel, members: {$in: [client.userID]}})
			setActiveChannel(channels[0])
		}
	}, [initialChannel])
}
