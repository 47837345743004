import {PrivateRoute, ProtectedRoute, PublicRoute} from 'components/private_route'

import {TableIndex, TableEdit, TableNew, TableShow, TableExport, NewSubscription, EditSubscription} from './index'
import {EXPORT_ROLES} from 'cores/exports2/constants'

const props = {roleInAnyEnv: EXPORT_ROLES}

const exportForegroundRoutes = [
	<ProtectedRoute {...props} path="/exports2/tables" component={TableIndex} exact />,
	<ProtectedRoute roleInAnyEnv={'admin'} path="/exports2/tables/new" component={TableNew} exact />,
	<ProtectedRoute {...props} path="/exports2/tables/:id" component={TableShow} exact />,
	<ProtectedRoute roleInAnyEnv={'admin'} path="/exports2/tables/:id/edit" component={TableEdit} />,
]
const exportBackgroundRoutes = [
	<ProtectedRoute {...props} path="/exports2/tables/:id/export" exact component={TableExport} />,
	<ProtectedRoute {...props} path="/exports2/tables/:id/subscriptions/new" exact component={NewSubscription} />,
	<ProtectedRoute {...props} path="/exports2/tables/:table_id/subscriptions/:id" exact component={EditSubscription} />,
	// <ProtectedRoute path="/exports2/tables/:id" role="admin" exact component={Settings} />,
]

export {exportForegroundRoutes, exportBackgroundRoutes}
