import {find} from '@eitje/easy_api'
import {delUser, inviteUser} from 'actions/environment'
import {ConfirmButton, DeleteButton} from 'common/components'
import 'components/routing' // WATCH OUT: this import is needed, otherwise -> ReferenceError: Cannot access 'useList' before initialization.
// has something to do with helpers/users
import {t} from 'initializers/i18n'
import {Fragment} from 'react'
import {useSelector} from 'react-redux'
import {envIdSelector} from 'selectors/records'
import './styles/users.less'

export const DelButton = ({user}) => {
	const envId = useSelector(envIdSelector)
	return (
		<DeleteButton nonDestructive onDel={() => delUser(envId, user.id)} iconLeft="person-minus">
			{user.voornaam} {t('outOfService')}
		</DeleteButton>
	)
}

export const DelPopOver = ({user, popover, buttonStyle, style}) => {
	const act = async () => {
		await inviteUser({email: user.email})
		popover?.hide()
	}

	return (
		<div className="rehire-popout" style={style}>
			<ConfirmButton width="full" style={buttonStyle} onClick={() => act()}>
				{t('takeBackEmployee')}
			</ConfirmButton>
		</div>
	)
}

export const ContractEnv = ({id}) => {
	const env = useSelector(state => find(state, 'environments', id))
	return <Fragment> {env.naam} </Fragment>
}
