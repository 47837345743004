import {useCallback} from 'react'
import {useShared} from 'hooks'
import {EitjeButton, Text} from 'common/components'
import {CHANNEL_TYPES, useChatWindowContext, useCreateChannel} from 'cores/chat'

const t = 'common.start_chat'

const useGetOpenDirectChannel = userId => {
	const {createChannel} = useCreateChannel()
	const {openChatWindow} = useChatWindowContext()

	const {me} = useShared()

	const isAllowed = userId !== me.id
	const onClick = useCallback(async () => {
		if (!isAllowed) return

		const res = await createChannel({
			type: CHANNEL_TYPES.DIRECT,
			userIds: [userId, me.id],
		})

		openChatWindow(res)
	}, [isAllowed, me.id, userId])

	return {isAllowed, onClick}
}

export const useOpenDirectChatText = userId => {
	const {isAllowed, onClick} = useGetOpenDirectChannel(userId)

	if (!isAllowed) return null

	return <Text t={t} onClick={onClick} />
}

export const OpenDirectChatButton = ({userId, ...rest}) => {
	const {isAllowed, onClick} = useGetOpenDirectChannel(userId)

	if (!isAllowed) return null

	return (
		<EitjeButton iconLeft="chat" {...rest} onClick={onClick}>
			<Text t={t} />
		</EitjeButton>
	)
}
