import {buildColumn, XLARGE_CELL_WIDTH} from 'common/components/advanced_table'
import {t} from 'initializers/i18n'

const managerRole = {
	enableRole: 'manager',
	roleEnv: shift => shift.team.environment_id,
}

const contractRole = {
	enableRole: 'contracten',
	roleEnv: shift => shift.team.environment_id,
}

export const columns = [
	{
		id: 'user_id',
		accessorFn: shift => shift.user?.full_name || t('common.open_shift'),
		width: XLARGE_CELL_WIDTH,
		aggregatesFn: 'countUniqueValues',
	},
	{
		id: 'date',
		cell: 'DateCell',
	},
	{
		accessorKey: 'published',
		cell: 'BooleanCell',
		aggregates: true,
		collapsibleCell: true,
	},
	{
		id: 'conflict',
		cell: 'StatusCell',
		accessorFn: shift => (shift.conflict ? 'conflict' : null),
		aggregatesFn: 'sumBooleans',
	},
	{
		id: 'start',
		cell: 'TimeCell',
		accessorFn: shift => shift.startTime(),
	},
	{
		id: 'end',
		cell: 'TimeCell',
		accessorFn: shift => shift.endTime(),
	},
	{
		id: 'total',
		cell: 'TimeCell',
		accessorFn: shift => shift.totalMins(),
		aggregates: true,
	},
	{
		id: 'team_id',
		accessorFn: shift => shift.team?.naam,
		collapsibleCell: true,
		aggregatesFn: 'countUniqueValues',
	},
	{
		id: 'environment_id',
		accessorFn: shift => shift.team?.environment?.naam,
		collapsibleCell: true,
		defaultVisible: false,
		aggregatesFn: 'countUniqueValues',
	},
	{
		accessorKey: 'pauze_duur',
		cell: 'TimeCellWithZero',
	},
	{
		id: 'meal',
		accessorFn: shift => shift.maaltijd?.length,
		sortingFn: 'basic',
		aggregates: true,
		aggregatesFn: 'sumValues',
		collapsibleCell: 'BasicCell',
	},
	{
		id: 'shift_type',
		accessorFn: ({typ}) => {
			if (!typ || typ === 'standaard') return null
			return typ
		},
		defaultVisible: false,
	},
	{
		id: 'trade_request_id',
		cell: 'BooleanCell',
		accessorFn: shift => !!shift.hasTradeRequest(),
	},
	{
		id: 'function_allowance_id',
		accessorFn: shift => shift.allowance?.name,
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'skill_set_id',
		accessorFn: shift => shift.skillSet?.name,
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'registered_shift_id',
		cell: 'BooleanCell',
		accessorFn: shift => !!shift.reg_shift_id,
		aggregates: true,
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'remarks',
		accessorFn: shift => (_.isEmpty(shift.remarks) ? null : shift.remarks), // group "" and `undefined` together
		defaultVisible: false,
		...managerRole,
	},
	{
		id: 'active_contract',
		accessorFn: shift => shift.activeContract,
		cell: 'BooleanCell',
		defaultVisible: false,
	},
	{
		id: 'contract_environment_id',
		accessorFn: shift => shift.contractEnvironmentId,
		cell: 'EnvironmentCell',
		defaultVisible: false,
	},
	{
		id: 'contract_start_date',
		accessorFn: shift => shift.contractStartDate,
		cell: 'DateCell',
		defaultVisible: false,
		...contractRole,
	},
	{
		id: 'contract_end_date',
		accessorFn: shift => shift.contractEndDate,
		cell: 'DateCell',
		defaultVisible: false,
		...contractRole,
	},
	{
		id: 'contract_type',
		accessorFn: shift => shift.employmentType,
		defaultVisible: false,
		...contractRole,
	},
	{
		id: 'gross_hourly_wage',
		accessorFn: shift => shift.grossHourlyWage,
		cell: 'MoneyCell',
		defaultVisible: false,
		...contractRole,
	},
	{
		id: 'hourly_labor_costs',
		accessorFn: shift => shift.costPerHours,
		cell: 'MoneyCell',
		defaultVisible: false,
		...contractRole,
	},
].map(buildColumn)
